import axios from 'axios';
import { Auth } from 'aws-amplify';

export async function ApiClient() {
  const myAxios = axios.create();
  const token = (await Auth.currentAuthenticatedUser())
    .getSignInUserSession()
    .getIdToken()
    .getJwtToken();

  const axiosConfig = {
    headers: {
      Authorization: token,
    },
  };

  myAxios.interceptors.request.use((req) => {
    req.headers = axiosConfig.headers;
    return req;
  });

  myAxios.interceptors.response.use(
    (res) => res,
    (error) => {
      const response = error.response;
      if (response) {
        if (response.status === 401) {
          //re-authenticate user
          Auth.signOut();
          throw new ApiError(
            'You are not logged in',
            error.response.status,
            error,
          );
        } else {
          if (response.data && response.data.message) {
            throw new ApiError(
              response.data.message,
              error.response.status,
              error,
            );
          }
        }
      }

      throw error;
    },
  );

  return myAxios;
}

export class ApiError extends Error {
  constructor(
    readonly message: string,
    readonly statusCode: number,
    readonly originalError: Error,
  ) {
    super(message);
  }
}

export default ApiClient;
