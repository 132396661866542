import React, { FC, useEffect, useState } from 'react';
import {
  CheckOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Card, Col, Timeline, Tooltip } from 'antd';
import { IKycChecks } from '../../../../interfaces/IUserKycData';

type Props = {
  availableTiers: number[];
  currentTier: number;
  passed: Array<IKycChecks>;
  failed: Array<IKycChecks>;
  pending: Array<IKycChecks>;
};

type Checks = {
  name: string;
  tier: number;
  order: number;
  status: 'passed' | 'pending' | 'failed';
  color: string;
};

type TimelineState = {
  tier: number;
  checks: Checks[];
}[];

export const TierTimeline: FC<Props> = (props) => {
  const [timelineState, setTimelineState] = useState<TimelineState>([]);

  const getDataForTier = (tier: number) => {
    const result: Checks[] = [];

    ['passed', 'failed', 'pending'].forEach((status) => {
      (props as any)[status]
        .filter((v: IKycChecks) => v.tier === tier)
        .forEach((v: IKycChecks) =>
          result.push({
            order: v.order,
            status: status as any,
            name: v.name,
            tier: v.tier,
            color: ({
              pending: 'orange',
              passed: 'green',
              failed: 'red',
            } as any)[status],
          }),
        );
    });

    result.sort((a, b) => (a.order > b.order ? 1 : -1));
    return result;
  };

  useEffect(() => {
    const newTimelineState: TimelineState = [];
    props.availableTiers
      .sort()
      .forEach((tier) =>
        newTimelineState.push({ tier, checks: getDataForTier(tier) }),
      );
    setTimelineState(newTimelineState);
  }, [props.availableTiers]);

  return (
    <Col span={24}>
      <Timeline mode="left">
        {timelineState.map((state) => {
          return (
            <Timeline.Item
              dot={
                <ClockCircleOutlined
                  style={{
                    fontSize: '16px',
                    color: state.tier <= props.currentTier ? 'green' : 'orange',
                  }}
                />
              }
              key={state.tier}
            >
              <Card>
                <h3>{`Tier ${state.tier} ${
                  props.currentTier === state.tier ? '(User is here)' : ''
                }`}</h3>

                {state.checks.map((check) => (
                  <CheckStateUI {...check} />
                ))}
              </Card>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </Col>
  );
};

function fromKebab(s: string): string {
  return s.split('_').join(' ');
}

const CheckStateUI: FC<Checks> = ({ name, status }) => {
  return (
    <div>
      <Tooltip title={status}>
        {status === 'passed' && <CheckOutlined style={{ color: 'green' }} />}
        {status === 'pending' && (
          <WarningOutlined style={{ color: 'orange' }} />
        )}
        {status === 'failed' && (
          <ExclamationCircleOutlined style={{ color: 'red' }} />
        )}
        <span style={{ marginLeft: 10 }}>{fromKebab(name)}</span>
      </Tooltip>
    </div>
  );
};
