import React, { FC } from 'react';
import logo from './../../assets/logo-full.png';
import smallLogo from './../../assets/logo-full.png';

interface AppLogoProps {
  collapsed: boolean;
  smallLogo: boolean;
}

const AppLogo: FC<AppLogoProps> = (props) => {
  return (
    <div style={{ padding: '15px 0' }}>
      {!props.smallLogo ? (
        <div className="logo" style={{ justifyContent: 'flex-start' }}>
          <img
            src={logo}
            style={{ marginLeft: '24px', width: '160px' }}
            alt="logo"
          />
        </div>
      ) : (
        <div className="logo">
          <img src={smallLogo} style={{ width: '40px' }} alt="logo" />
        </div>
      )}
    </div>
  );
};

export default AppLogo;
