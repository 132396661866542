import React, { FC } from 'react';

const LayoutContent: FC<{
  pageTitle?: string;
  pageSubTitle?: string;
}> = (props) => {
  return (
    <div
      style={{
        backgroundColor: '#FFFFFF',
        margin: '20px',
        padding: '20px',
        minHeight: '90%',
      }}
    >
      {props.pageTitle && (
        <h1 className="title" style={{ fontSize: '1.5em', margin: 0 }}>
          {props.pageTitle}
        </h1>
      )}
      {props.pageSubTitle && (
        <h4 className="sub-title" style={{ color: 'grey' }}>
          {props.pageSubTitle}
        </h4>
      )}
      {props.children}
    </div>
  );
};

export default LayoutContent;
