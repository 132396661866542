import React, { FC, useState, useEffect, useCallback } from 'react';
import { Layout, Drawer } from 'antd';
import { withRouter } from 'react-router-dom';
import { SiderProps } from 'antd/lib/layout';
import './admin-layout.scss';
import AdminSidebar from './AdminSidebar';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import AdminHeader from './AdminHeader';
import AppLogo from './AppLogo';
import { Auth } from 'aws-amplify';

const { Header, Sider, Content } = Layout;

/**
 * Application Layout fot Authenticated Admin Users
 * @param props
 */
const AdminLayout: FC = (props) => {
  const [sideBarCollapsed, setSidebarCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleSideBar = () => setIsMobile(!isMobile);

  const updateDimensions = useCallback(() => {
    if (window.screen.availWidth < 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [setIsMobile, isMobile]);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', () => updateDimensions());
    return () => {
      window.removeEventListener('resize', () => updateDimensions());
    };
  }, [updateDimensions]);

  const siderProps: SiderProps = {
    trigger: null,
    collapsible: true,
    collapsed: sideBarCollapsed,
    width: 240,
  };

  const triggerIconProps = {
    style: { fontSize: '20px', marginLeft: '10px' },
    onClick: () => toggleSideBar(),
  };

  const logout = () => {
    Auth.signOut({ global: true });
  };

  return (
    <Layout style={{ minHeight: '100vh' }} className={`back-office`}>
      {!isMobile && (
        <Sider {...siderProps}>
          <AppLogo collapsed={sideBarCollapsed} smallLogo={sideBarCollapsed} />
          <AdminSidebar />
        </Sider>
      )}

      {isMobile && (
        <Drawer
          style={{ background: 'linearGradient(#005E81, #00222F)' }}
          closable={false}
          visible={sideBarCollapsed}
          placement={`left`}
          onClose={() => setSidebarCollapsed(!sideBarCollapsed)}
          width={240}
        >
          <AppLogo collapsed={sideBarCollapsed} smallLogo={false} />
          <AdminSidebar
            collapse={() => setSidebarCollapsed(!sideBarCollapsed)}
          />
        </Drawer>
      )}

      <Layout>
        <Header
          style={{
            background: '#fff',
            padding: 0,
            boxShadow: '0 2px 4px 0px rgba(0, 0, 0, 0.1)',
          }}
        >
          {sideBarCollapsed && <MenuUnfoldOutlined {...triggerIconProps} />}
          {!sideBarCollapsed && <MenuFoldOutlined {...triggerIconProps} />}
          <AdminHeader logout={() => logout()} />
        </Header>
        <Content>{props.children}</Content>
      </Layout>
    </Layout>
  );
};

export default withRouter(AdminLayout);
