import React, { FC, CSSProperties } from 'react';
import { Menu } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  HomeOutlined,
  PoweroffOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Auth } from 'aws-amplify';

interface OwnProps {
  collapse?: () => void;
}

type AppSideBarProps = OwnProps & RouteComponentProps;

const AdminSidebar: FC<AppSideBarProps> = ({ collapse, history, location }) => {
  const textStyles: CSSProperties = {
    fontSize: 14,
  };

  const goTo = (path: string): void => {
    if (collapse) {
      collapse();
    }
    history.push(path);
  };

  const getSelectedKey = (): string => {
    return location.pathname;
  };
  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={[getSelectedKey()]}>
      <Menu.Item
        key={'/dashboard'}
        icon={<HomeOutlined />}
        onClick={() => goTo('/dashboard')}
      >
        <span style={textStyles}>{'Dashboard'}</span>
      </Menu.Item>
      <Menu.Item
        key={'/clients'}
        icon={<UserOutlined />}
        onClick={() => goTo('/clients')}
      >
        <span style={textStyles}>{'Clients'}</span>
      </Menu.Item>

      <Menu.Item
        key={'/logout'}
        icon={<PoweroffOutlined />}
        onClick={() => Auth.signOut()}
      >
        <span style={textStyles}>{'Sign out'}</span>
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(AdminSidebar);
