import React, { FC } from 'react';
import LayoutContent from '../../components/layout/LayoutContent';

const Dashboard: FC<{}> = () => {
  return (
    <LayoutContent
      pageTitle="Dashboard"
      pageSubTitle="Fairmoney administration"
    ></LayoutContent>
  );
};

export default Dashboard;
