import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import React from 'react';
import logo from '../../assets/logo-full.png';

export default function SignIn() {
  return (
    <div
      className={'signIn'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={logo} style={{ width: 200 }} />
      </div>
      <AmplifyAuthenticator>
        <AmplifySignIn slot="sign-in" hideSignUp={true} />
      </AmplifyAuthenticator>
    </div>
  );
}
