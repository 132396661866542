import React, { FC, useState } from 'react';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import LayoutContent from '../../components/layout/LayoutContent';
import { KycApi } from '../../api/KycApi';
import { IUserKycData } from '../../interfaces/IUserKycData';
import { Modal, Tabs } from 'antd';
import { ClientKyc } from './components/kyc/ClientKyc';

export interface ClientState {
  userCode: string;
  user_id: number;
  kyc: IUserKycData;
  info: {
    email: string;
    phone: string;
  };
}

const Clients: FC<{}> = () => {
  const [form] = Form.useForm();
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [clientState, setClientState] = useState<ClientState | null>(null);

  const onUserCodeSubmited = ({ userCode = '' }) => {
    fetchClientByCode(userCode);
  };

  const fetchClientByCode = (userCode: string) => {
    setIsFetchingUser(true);
    setClientState(null);
    KycApi.findKycDataByUserCode(userCode)
      .then(({ data: { data } }) => {
        setClientState({
          user_id: data.user_id,
          kyc: data.kyc_result,
          info: {
            email: data.user_email,
            phone: data.user_phone_number,
          },
          userCode,
        });
      })
      .catch((err) => Modal.error({ content: err.message }))
      .finally(() => setIsFetchingUser(false));
  };

  const refresh = () => {
    if (!clientState) return;
    fetchClientByCode(clientState.userCode);
  };

  return (
    <LayoutContent
      pageTitle="Clients"
      pageSubTitle="Fairmoney client information"
    >
      <Row style={{ padding: '2em 0' }}>
        <Col>
          <Form layout="inline" form={form} onFinish={onUserCodeSubmited}>
            <Form.Item
              label="User Code"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'User code is required',
                },
              ]}
              name="userCode"
            >
              <Input placeholder="User code" size="large" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isFetchingUser}
                size="large"
              >
                Search
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      {clientState && (
        <Row>
          <Col span={24}>
            <Card
              title={`User code: ${clientState.userCode}`}
              headStyle={{ backgroundColor: 'rgba(138, 203, 116, 0.24)' }}
            >
              <Tabs defaultActiveKey="kyc">
                <Tabs.TabPane tab="KYC Information" key="kyc">
                  <ClientKyc {...clientState} onRefresh={refresh} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Others" key="others">
                  Other Info
                </Tabs.TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      )}
    </LayoutContent>
  );
};

export default Clients;
