import React from 'react';
import { StoreProvider } from 'easy-peasy';

import store from './store';

import AppRoutes from './routes/index';

function App() {
  return (
    <StoreProvider store={store}>
      <AppRoutes />
    </StoreProvider>
  );
}

export default App;
