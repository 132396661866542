const {
  REACT_APP_COGNITO_APP_CLIENT_ID,
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_COGNITO_USER_POOL_ID,
} = process.env;

export const awsCognitoConfig = {
  Auth: {
    mandatorySignIn: true,
    region: REACT_APP_COGNITO_REGION,
    userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
  },
};
