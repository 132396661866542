import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import Amplify from 'aws-amplify';
import AdminLayout from '../components/layout/AdminLayout';
import { awsCognitoConfig } from '../config/aws-config';
import Dashboard from '../pages/dashboard/Dashboard';
import Clients from '../pages/clients/Clients';
import SignIn from '../pages/auth/SignIn';

Amplify.configure(awsCognitoConfig);

function AppRoutes() {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object | undefined>();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <>
      <Router>
        <AdminLayout>
          <Switch>
            <Route path="/clients">
              <Clients />
            </Route>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/">
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        </AdminLayout>
      </Router>
    </>
  ) : (
    <SignIn />
  );
}
export default AppRoutes;
