import { Button, Col, Form, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Modal } from 'antd';
import React, { useState } from 'react';
import { FC } from 'react';
import { KycApi } from '../../../../api/KycApi';
import { ClientState } from '../../Clients';
import { TierTimeline } from './TierTimeline';

type ClientKycProps = ClientState & { onRefresh: () => void };

export const ClientKyc: FC<ClientKycProps> = (props) => {
  const { user_id, kyc, userCode, info } = props;

  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [form] = Form.useForm();

  const onUpdateRequested = (values: any) => {
    setIsUpdating(true);
    KycApi.updateUserKycTier(
      user_id.toString(),
      Number(values.tier),
      values.metadata,
    )
      .then((response) => {
        setUpdateModalOpen(false);
        setIsUpdating(false);
        Modal.success({
          content: 'User tier updated sucessfully',
          onOk: () => props.onRefresh(),
          onCancel: () => props.onRefresh(),
        });
      })
      .catch((err) => Modal.error({ content: err.message }))
      .finally(() => {
        setIsUpdating(false);
        setUpdateModalOpen(false);
      });
  };

  const availableTiers = [0, 1, 2, 3];

  return (
    <Row style={{ padding: 10 }}>
      <Col span={12}>
        <DataView title={'User ID'} value={user_id.toString()} />
        <DataView title={'User Code'} value={userCode} />
        <DataView title={'Email'} value={info.email || 'N/A'} />
        <DataView title={'Phone'} value={info.phone || 'N/A'} />
        <hr style={{ border: 0, borderTop: '1px solid #EEEEEE' }}></hr>
        <div style={{ marginTop: '2em' }}>
          <Button type="default" onClick={() => setUpdateModalOpen(true)}>
            Update KYC Tier
          </Button>
        </div>
      </Col>
      <Col span={12} style={{ padding: '1em' }}>
        <TierTimeline
          availableTiers={availableTiers}
          passed={kyc.passed}
          failed={kyc.failed}
          pending={kyc.pending}
          currentTier={kyc.tier}
        />
      </Col>

      <Modal
        visible={updateModalOpen}
        onCancel={() => (!isUpdating ? setUpdateModalOpen(false) : null)}
        footer={false}
        centered={true}
        title={`Update KYC Tier`}
      >
        <Row>
          <Form
            layout="vertical"
            form={form}
            onFinish={onUpdateRequested}
            style={{ width: '100%' }}
          >
            <Form.Item
              label="Select new tier"
              rules={[
                {
                  type: 'number',
                  required: true,
                  message: 'New tier is required',
                },
              ]}
              name="tier"
            >
              <Select placeholder="New tier" size="large">
                {availableTiers.map((tier) => (
                  <Select.Option
                    value={tier}
                    disabled={tier === props.kyc.tier}
                  >
                    Tier {tier}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Notes about this change"
              rules={[
                {
                  required: false,
                  whitespace: true,
                },
              ]}
              name="metadata"
            >
              <TextArea placeholder="Notes" size="large" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isUpdating}
                size="large"
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Modal>
    </Row>
  );
};

type DataViewPRops = { title: string; value: string };

const DataView: FC<DataViewPRops> = ({ title, value }) => {
  return (
    <div style={{ marginBottom: '1em' }}>
      <span style={{ fontSize: '1em', fontWeight: 'bold' }}>{title}</span>
      <br></br>
      <span style={{ fontSize: '1.4em', color: 'grey' }}>{value}</span>
    </div>
  );
};
