import React, { FC, useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import './app-header.scss';
import { DownOutlined, PoweroffOutlined } from '@ant-design/icons';
import Auth from '@aws-amplify/auth';

interface OwnProps {
  logout: () => void;
}

type AppHeaderProps = OwnProps & RouteComponentProps;

const AppHeader: FC<AppHeaderProps> = ({ logout }) => {
  const [user, setUser] = useState<any>('');

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setUser(user);
    });
  }, []);

  return (
    <div className={'right-wrapper'}>
      {/* <div className={`item`}>
        <NotificationFilled />
      </div>
      <div className={`item`}>
        <SettingFilled />
      </div> */}
      <div className={`item`}>
        <Dropdown
          overlay={menu(logout)}
          trigger={['click']}
          className={'user-menu-dropdown'}
        >
          <a
            className="ant-dropdown-link"
            href="#"
            style={{
              textDecoration: 'none',
              color: '#ffffff',
              textTransform: 'capitalize',
            }}
          >
            <span>
              <span
                style={{
                  color: '#828282',
                  marginRight: '10px',
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                {user.username}
              </span>
              <DownOutlined
                style={{ color: '#828282', display: 'inline', fontSize: 14 }}
              />
            </span>
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

const menu = (logout: () => void) => (
  <Menu>
    <Menu.Item key="1">
      <a onClick={() => logout()}>
        <PoweroffOutlined /> Logout
      </a>
    </Menu.Item>
  </Menu>
);

export default withRouter(AppHeader);
