import { IUserKycData } from '../interfaces/IUserKycData';
import { AxiosResponse } from 'axios';
import ApiClient from '../config/apiClient';
const apiClient = ApiClient();

const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/admin`;

export interface GetKycResponse {
  user_id: number;
  user_phone_number: string;
  user_email: string;
  kyc_result: IUserKycData;
}
const findKycDataByUserCode = async (
  userCode: string,
): Promise<AxiosResponse<{ data: GetKycResponse }>> => {
  return (await apiClient).get(`${BASE_URL}/kyc/status/${userCode}`);
};

const refreshUserKyc = async (userId: string) => {
  return (await apiClient).get(`${BASE_URL}/refresh_kyc/${userId}`);
};

const updateUserKycTier = async (
  userId: string,
  newTier: number,
  metadata: string,
) => {
  const body = {
    user_id: userId,
    tier: newTier,
    metadata: metadata || '',
  };
  return (await apiClient).post(
    `${BASE_URL}/kyc/status/${userId}/update_tier`,
    body,
  );
};

export const KycApi = {
  findKycDataByUserCode,
  refreshUserKyc,
  updateUserKycTier,
};
